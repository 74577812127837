import Typed from 'typed.js';

// Funktion, die prüft, ob das Element existiert, und dann Typed initialisiert
function initTyped(selector, options) {
  const element = document.querySelector(selector);
  if (element) {
    new Typed(selector, options);
  }
}

// Initialisierung der verschiedenen Typed-Instanzen mit Prüfung auf Existenz der ID

// (Imagefilm?Recruiting Film/Produktfilm/Social Media Content. Wortschaltung hier?)
initTyped('#typed1', {
  strings: ['Imagefilm?', 'Recruiting Film?', 'Produktfilm?', 'Social Content?'],
  typeSpeed: 50,
  loop: true
});

// klescht (bangt/klatscht/knallt/fetzt. Als Wortwechsel durchschalten möglich?)
initTyped('#typed2', {
  strings: ['klescht', 'bangt', 'klatscht', 'knallt', 'fetzt'],
  typeSpeed: 30,
  loop: true
});

// Filme/Videos/Bewegtbilder/Kampagnen/Contents?
initTyped('#typed3', {
  strings: ['Filme?', 'Werbespots?', 'Bewegtbilder?', 'Kampagnen?', 'Posts?'],
  typeSpeed: 80,
  loop: true
});

// image film/case study/commercial/social content
initTyped('#typed1en', {
  strings: ['image film?', 'case study?', 'commercial?', 'social content?'],
  typeSpeed: 50,
  loop: true
});

// klescht (banging/slapping/rocking/rolling)
initTyped('#typed2en', {
  strings: ['banging', 'slapping', 'rocking', 'rolling'],
  typeSpeed: 30,
  loop: true
});

// moving pictures/campaigns/postings/videos?
initTyped('#typed3en', {
  strings: ['moving pictures?', 'campaigns?', 'postings?', 'videos?', 'commercials?'],
  typeSpeed: 80,
  loop: true
});
